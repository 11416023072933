import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject, throwError, forkJoin } from 'rxjs';
import { mergeMap, take, catchError, map, retry } from 'rxjs/operators';
import { EnVar } from '../config';

@Injectable({
	providedIn: 'root'
})
export class PractitionerService {

	apiurl = EnVar.apiurl;
	constructor(private http: HttpClient) { }
	getSechudel(ckey: any, prkey: any, atkey: any, date: any): Observable<any> {
		var query = "";
		query += "?pkey=" + prkey;
		query += "&atkey=" + atkey;
		if (date != "") {
			query += "&date=" + date;

		}

		var urls = this.apiurl + "appointment/bookingavaltime/" + ckey + query;

		return this.http.get<any>(urls);



	}
	getAvlDate(ckey: any, prkey: any, atkey: any, month: any): Observable<any> {
		var query = "";
		query += "?pkey=" + prkey;
		query += "&atkey=" + atkey;
		if (month != "") {
			query += "&month=" + month;

		}

		var urls = this.apiurl + "appointment/bookingavaldate/" + ckey + query;

		return this.http.get<any>(urls);



	}
}
