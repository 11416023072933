<div class="card">
  <div class="card-header bg-transparent">
    <ul class="nav align-items-center justify-content-between">
      <li class="nav-item">
        <a
          class="nav-link active"
          href="#"
          (click)="previous($event)"
          [ngClass]="{
            disabled:
              currentMonth <= today.getMonth() &&
              currentYear <= today.getFullYear()
          }"
          ><i class="las la-angle-left fs-20"></i
        ></a>
      </li>
      <li class="nav-item">
        <h4 class="mb-0">{{ CalenderTitle }}</h4>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="next($event)"
          ><i class="las la-angle-right fs-20"></i
        ></a>
      </li>
    </ul>
  </div>

  <div class="card-body p-0">
    <table class="table table-borderless table-sm mb-0 caltable">
      <thead>
        <tr class="fs-13">
          <th
            *ngFor="let weekname of Weeks; let dd = index"
            class="text-center week-{{ dd }}"
          >
            {{ weekname }}
          </th>
        </tr>
      </thead>
      <tbody #calBody>
        <tr *ngFor="let wday of CalenderList">
          <td
            *ngFor="let dayDate of wday; let dd = index"
            class="week-{{ dd }}"
          >
            <div class="text-center datebox">
              <div
                *ngIf="
                  dayDate != 0 && dayDate['date'].getTime() < today.getTime()
                "
                class="previous date-content"
              >
                <span class="data_num">{{ dayDate["day"] }}</span>
              </div>
              <div
                *ngIf="
                  dayDate != 0 &&
                  dayDate['date'].getTime() >= today.getTime() &&
                  dayDate['isaval'] <= 0
                "
                class="previous date-content"
              >
                <span class="data_num">{{ dayDate["day"] }}</span>
              </div>
              <a
                href="javascript:void(0);"
                *ngIf="
                  dayDate != 0 &&
                  dayDate['date'].getTime() >= today.getTime() &&
                  dayDate['isaval'] > 0
                "
                (click)="displayTime($event, dayDate, calBody)"
                class="date-content clickDate"
                [ngClass]="{
                  active:
                    SelectedDate &&
                    dayDate['date'].getTime() == SelectedDate.getTime(),
                  current:
                    today.getDate() + '-' + today.getMonth() ==
                    dayDate['date'].getDate() + '-' + dayDate['date'].getMonth()
                }"
                ><span class="data_num">{{ dayDate["day"] }} </span>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
