export const EnVar = {
apiurl : 'https://bookonline.getback.health/v1/',
initpk : '75BF6996-4E20-4EAA-96B0-87EF2F5D7F64',
initclinic : 'Middle Park Physiotherapy'
};

/*export const EnVar = {
apiurl : 'http://bookonline.hbs18.solutions/v1/',
initpk : 'AFD4E012-A125-4891-BBE3-7D8794C79824',
initclinic : 'Middle Park Physiotherapy'
};*/
