import { HttpClient } from '@angular/common/http';
import { EnVar } from '../config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/clinic.service";
import * as i3 from "@angular/common/http";
export class Initlist {
    constructor(router, CS, http) {
        this.router = router;
        this.CS = CS;
        this.http = http;
        this.initclinik = EnVar.initclinic;
        this.initpk = EnVar.initpk;
    }
    resolve(route, state) {
        localStorage.removeItem('auth');
        localStorage.removeItem('clinic');
        let self = this;
        var localCLinic = localStorage.getItem('clinicdata');
        var clinicdataS = JSON.parse(localStorage.getItem('clinicdata'));
        //var usertoken = btoa(clinicdataS['pk'] + ":" + clinicdataS['name'] + ":" + clinicdataS['OnlineBookingType'] + ":" + clinicdataS['IsOnlinePayment']);
        var NewUserData = JSON.stringify({ "pk": clinicdataS['pk'], "name": clinicdataS['name'], "OnlineBookingType": clinicdataS['OnlineBookingType'], "IsOnlinePayment": clinicdataS['IsOnlinePayment'] });
        /*	if (clinicdataS) {
            } else {
                return null;
            }*/
        var newUsertoken = btoa(NewUserData);
        localStorage.setItem("auth", newUsertoken);
        return this.CS.getservices();
    }
}
Initlist.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Initlist_Factory() { return new Initlist(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ClinicService), i0.ɵɵinject(i3.HttpClient)); }, token: Initlist, providedIn: "root" });
