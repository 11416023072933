import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NgxXml2jsonService } from 'ngx-xml2json';
@Injectable({
  providedIn: 'root'
})
export class ClinicdataGuard implements CanActivate {
  constructor(private http: HttpClient, private ngxXml2jsonService: NgxXml2jsonService, private titleService: Title) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var clinicData = localStorage.getItem("clinicdata");
    let self = this;

    /*if (clinicData == null) {
    } else {
      var jsonOld = JSON.parse(clinicData);
      this.titleService.setTitle(jsonOld["title"]);

      return true;
    }*/
    return this.http.get('./assets/clinic.xml', {
      headers: new HttpHeaders()
        .set('Content-Type', 'text/xml')
        .append('Access-Control-Allow-Methods', 'GET')
        .append('Access-Control-Allow-Origin', '*')
        .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
      responseType: 'text'
    }).pipe(
      map(data => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, 'text/xml');
        const result = this.ngxXml2jsonService.xmlToJson(xml);
        if (result && result['clinicdata']) {
          var clinicdataNew = result['clinicdata'];
          result['clinicdata']["stripekey"] = btoa(result['clinicdata']["stripekey"]);
          localStorage.setItem("clinicdata", JSON.stringify(result['clinicdata']));
        }

        return true;
      }))


  }

  xmlToJson(xml) {

    var obj = {};

    if (xml.nodeType == 1) {

      if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
        for (var j = 0; j < xml.attributes.length; j++) {
          var attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) {
      obj = xml.nodeValue;
    }

    if (xml.childNodes) {
      for (var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof (obj[nodeName]) == "undefined") {
          obj[nodeName] = this.xmlToJson(item);
        } else {
          if (typeof (obj[nodeName].push) == "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    }
    return obj;
  }

}
