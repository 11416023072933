import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fadeInUpAnimation } from '../../_animation/fade-in-up.animation';

import {
	ActivatedRoute,
	RouterStateSnapshot, ActivatedRouteSnapshot
} from '@angular/router';
import { PractitionerService } from '../../_services/practitioner.service';
import { UserFormComponent } from '../../user-form/user-form.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnVar } from '../../config';
declare var $: any;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [fadeInUpAnimation],
	host: {
		class: 'container-fluid'
	},
	//Pick Appointment Time
})
export class HomeComponent implements OnInit {
	steps: number = 1;
	apiurl = EnVar.apiurl;
	ClinicName: string;
	isloaded: boolean = false;
	@ViewChild('progressbar', { static: false }) progressbarView: ElementRef;

	progressWidth: number = 12.5;

	isMobile;
	initdata: object[] = [];
	dayFull: object = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
	imageUrl: object = [{ url: "002-solution.png", title: " ", desc: " " }, { url: "002-solution.png", title: "Select Patient Type", desc: " " }, { url: "002-solution.png", title: "Select Service", desc: "Please select a service you want to schedule appointment for" }, { url: "003-candidate-management.png", title: "Select a Practitioner", desc: "Please select a practitioner you want to perform your service" }, { url: "001-calendar.png", title: "Select Date & Time", desc: "Click on a date to see a timeline of available slots" }, { url: "001-calendar.png", title: "Pick Appt Time", desc: "Click on a green time slot to reserve it" }, { url: "006-id-card.png", title: "Enter Information", desc: "Please provide you contact details so we can send you a confirmation and other contact info" }, { url: "002-manual.png", title: "Thank You, Your Booking is Successful", desc: "Booking was successful" }];
	PatientType: object = [{ "Id": 1, "Name": "New Patient" }, { "Id": 2, "Name": "Current Patient" }];
	PatientTypeId: number = 0;
	PatientypeName: string = "";
	StepTitle: string = "";
	StepDesc: string = " ";
	serviceList: object[] = [];
	ClinicData: object[] = [];
	AppTypeList: object[] = [];
	AppTypeGroup: object[][] = [];
	prList: object[] = [];
	clinicKey: string = EnVar.initpk;
	SelectedService: string = "";
	SelectedServiceName: string = "";
	SelectedAppointType: string = "";
	SelectedAppointName: string = "";
	SelectedAppointTypeData: object = {};
	SelectedPract: string = "";
	SelectedPractName: string = "";
	SelectedPracData: object = {};
	SelectedDate: Date;
	SelectedTime: object = {};
	UserFormData: object = {};
	Avaldate: object[] = [];

	SelectedStime: Date = null;
	timetype: string = "";
	ClinicImageLogo: string = "";
	IsGetback: string = "";
	GetBackLogo: string = EnVar.apiurl + "/api/assets/gray-orange-logo.png";
	@ViewChild(UserFormComponent, { static: false }) userform: UserFormComponent;
	Stripe = null;
	Timeframe: object[] = [];
	TimePeriod: object[] = [];
	TimeNextHr: object[] = [];
	ispreloader: boolean = false;
	constructor(private deviceService: DeviceDetectorService, private route: ActivatedRoute, private PS: PractitionerService, private http: HttpClient) { }

	ngOnInit() {

		var token = localStorage.getItem('auth');
		var tdata = atob(token);

		var cdata = JSON.parse(tdata);

		this.ClinicData = JSON.parse(localStorage.getItem("clinicdata"));
		this.IsGetback = this.ClinicData["IsGetBack"];


		this.Stripe = Stripe(atob(this.ClinicData["stripekey"]));
		this.ClinicName = cdata["name"].replace(/-/g, " ");
		//this.isMobile = this.deviceService.isMobile();
		this.isMobile = false;
		const listdata = this.route.snapshot.data['list'];

		this.initdata = listdata;
		this.serviceList = listdata[0]['sdata'];
		this.AppTypeGroup = listdata[0]['data'];

		if (this.ClinicData["img"] && this.ClinicData["img"] != "") {
			this.ClinicImageLogo = this.apiurl + 'api/' + this.ClinicData["img"];
		}

		this.ClinicName = this.ClinicData["name"];
		this.StepTitle = this.imageUrl[this.steps - 1].title;
		this.clinicKey = cdata["pk"];

	}
	pad(num: number): string {
		let s = num + "";
		while (s.length < 2) s = "0" + s;
		return s;
	}
	Time12to24(time12h) {
		const [time, modifier] = time12h.split(' ');

		let [hours, minutes] = time.split(':');

		if (hours === '12') {
			hours = '00';
		}

		if (modifier === 'PM') {
			hours = parseInt(hours, 10) + 12;
		}

		return `${hours}:${minutes}`;
	}
	getDates(start: string, end: string, cdate: Date) {

		var BKS = start.split(':');
		var BKE = end.split(':');
		var rangepushBK = [];
		var BKStime = new Date(cdate.getFullYear(), cdate.getMonth(), cdate.getDate());
		BKStime.setHours(parseInt(BKS[0]));
		BKStime.setMinutes(parseInt(BKS[1]));
		var BKEtime = new Date(cdate.getFullYear(), cdate.getMonth(), cdate.getDate());
		BKEtime.setHours(parseInt(BKE[0]));
		BKEtime.setMinutes(parseInt(BKE[1]));
		var loop = BKStime;
		var duration = 15;
		if (this.SelectedAppointTypeData.hasOwnProperty('Duration')) {
			duration = parseInt(this.SelectedAppointTypeData["Duration"]);

		};

		while (loop <= BKEtime) {

			var sndateB = new Date(loop);
			rangepushBK.push(sndateB);
			loop.setTime(loop.getTime() + ((duration * 1000) * 60));
		}

		return rangepushBK;
	}

	splitArray(array, chunk) {
		var i, j, temparray = [];
		for (i = 0, j = array.length; i < j; i += chunk) {
			temparray.push(array.slice(i, i + chunk));

		}
		return temparray;
	}
	PatientSelected(stepper, tabloader, stepImage, patientT) {
		var PublicKey = patientT['Id'];

		this.PatientTypeId = PublicKey;
		this.PatientypeName = patientT['Name'];
		this.AppTypeList = this.AppTypeGroup[this.SelectedService].filter(item => {
			return parseInt(item["CustomerType"]) == this.PatientTypeId && item["CustomerType"] != "0"

		})
		this.next(stepper, tabloader, stepImage);

	}
	ServiceSelected(stepper, tabloader, stepImage, service) {
		var PublicKey = service['Id'];

		if (this.AppTypeGroup.hasOwnProperty(PublicKey)) {

			this.SelectedService = PublicKey;
			this.SelectedServiceName = service['Name'];
			this.next(stepper, tabloader, stepImage);
		} else {
			this.AppTypeList = [];
		}

	}
	AppTypeSelected(stepper, tabloader, stepImage, AppType) {
		var PublicKey = AppType['PublicKey'];
		this.SelectedAppointType = PublicKey;
		this.SelectedAppointName = AppType['Name'];
		var pdata = this.initdata[1]['data'];
		var newPrList = [];
		for (let inx in pdata) {

			if (pdata[inx].hasOwnProperty('Apptype')) {
				var PrAppType = pdata[inx]["Apptype"];

				if (PrAppType.indexOf(PublicKey) != -1) {
					newPrList.push(pdata[inx]);
				};
			};
		}
		if (newPrList.length > 0) {
			this.prList = this.splitArray(newPrList, 3);
			this.SelectedAppointTypeData = AppType;
			this.next(stepper, tabloader, stepImage);
		}
	}
	practitionerSelected(stepper, tabloader, stepImage, pdata) {
		var PublicKey = pdata['PublicKey'];
		this.SelectedPracData = pdata;
		this.SelectedPractName = pdata["first_name"];

		this.SelectedPract = PublicKey;
		var today: Date = new Date();
		var month = today.getMonth() + 1;

		this.ispreloader = true;
		this.PS.getAvlDate(this.clinicKey, this.SelectedPract, this.SelectedAppointType, month).subscribe(res => {
			this.ispreloader = false;
			if (res) {
				if (res.length > 0) {
					var nwfilter = res;
					this.Avaldate = nwfilter;
				}
			}

			this.next(stepper, tabloader, stepImage);
		});
		/*
		this.next(stepper, tabloader, stepImage);*/

	}

	convertTo24Hour(time) {
		var hours = parseInt(time.substr(0, 2));
		if (time.indexOf('AM') != -1 && hours == 12) {
			time = time.replace('12', '0');
		}
		if (time.indexOf('PM') != -1 && hours < 12) {
			time = time.replace(hours, (hours + 12));
		}
		return time.replace(/(AM|PM)/, '');
	}
	Timeselected(event, stepper, tabloader, stepImage, timetype) {

		if (event) {

			this.timetype = timetype;
			this.SelectedTime = event;
			this.SelectedStime = event.stime;

			this.next(stepper, tabloader, stepImage);
		}

	}
	formsubmit(stepper, tabloader, stepImage) {
		var isvalid = this.userform.submitform();
		if (isvalid) {
			this.UserFormData = isvalid;
			this.confirmBooking(stepper, tabloader, stepImage);

		}
	}
	confirmBooking(stepper, tabloader, stepImage) {
		//var isvalid=this.userform.submitform();
		if (this.UserFormData) {
			var url = this.apiurl + "appointment/";
			var sdate: Date = this.SelectedTime['stime'];
			var Edate: Date = this.SelectedTime['etime'];
			var appdate = this.pad(sdate.getDate()) + '-' + this.pad(sdate.getMonth() + 1) + '-' + sdate.getFullYear();
			var shr = this.pad(sdate.getHours());


			var appinfo = {
				apptype: this.SelectedAppointType,
				prac: this.SelectedPract,
				date: appdate,
				stime: this.pad(sdate.getHours()) + ":" + this.pad(sdate.getMinutes()),
				etime: this.pad(Edate.getHours()) + ":" + this.pad(Edate.getMinutes()),
			}

			var formData: any = new FormData();
			var isvalid = this.UserFormData;
			formData.append('app', JSON.stringify(appinfo));

			for (var name in isvalid) {

				formData.append(name, isvalid[name]);
			}



			this.ispreloader = true;
			var isonlinePayment = (this.SelectedAppointTypeData["IsOnlinePayment"] && this.SelectedAppointTypeData["IsOnlinePayment"] == "1") ? true : false;
			var isPaymentAllowed = (this.ClinicData["IsOnlinePayment"] == "1");

			formData.append('ispaid', (isPaymentAllowed && isonlinePayment) ? "yes" : "no");

			this.http.post<any>(url, formData).subscribe(data => {
				this.ispreloader = false;


				if (this.steps == 7 && isPaymentAllowed && isonlinePayment) {
					var lineitems = [];
					var today = new Date();
					var ddDate = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear() + '-' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds() + '' + today.getMilliseconds();

					let acronym = this.ClinicData["name"].split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
					var clinicShortName = 'Inv-' + acronym + "-L-" + ddDate;
					lineitems.push({
						"price": this.SelectedAppointTypeData["strpie_price_id"],
						quantity: 1
					});
					if (data["pk"]) {
						this.Stripe.redirectToCheckout({
							lineItems: lineitems,
							mode: 'payment',
							successUrl: this.ClinicData['orderurl'] + 'order/success?orderid=' + clinicShortName + '&session_id={CHECKOUT_SESSION_ID}&apid=' + data["pk"],
							cancelUrl: this.ClinicData['orderurl'] + 'order/failed?orderid=' + clinicShortName + '&session_id={CHECKOUT_SESSION_ID}&apid=' + data["pk"],
						}).then(function (result) {

						});
					}

				} else {

					this.next(stepper, tabloader, stepImage);
				}

			});


		}



	}
	calmonthChanged(month, stepper, tabloader, stepImage) {

		this.ispreloader = true;
		this.PS.getAvlDate(this.clinicKey, this.SelectedPract, this.SelectedAppointType, month).subscribe(res => {
			this.ispreloader = false;
			if (res) {
				if (res.length > 0) {
					var nwfilter = res;
					this.Avaldate = nwfilter;


				}
			}

		});


	}
	DateSlected(cdate, stepper, tabloader, stepImage) {
		if (this.steps) {

			//if(tabloader.children[this.steps-1]){

			this.Timeframe = [];
			this.TimeNextHr = [];
			this.Timeframe = [];
			this.SelectedDate = cdate;
			var clickDate = cdate.getFullYear() + '-' + this.pad(cdate.getMonth() + 1) + '-' + this.pad(cdate.getDate());
			if (this.SelectedPracData.hasOwnProperty('ScheduleData')) {
				var scData = this.SelectedPracData["ScheduleData"];
				if (scData) {
					var dasy = cdate.getDay();
					var dayname = this.dayFull[dasy];
					if (scData.hasOwnProperty(dayname)) {
						var ScDay = scData[dayname];
						var prSBreak = [];
						var prEBreak = [];

						var dayS = ScDay['start'].split(':');
						dayS = parseInt(dayS[0]);
						var dayE = ScDay['end'].split(':');
						dayE = parseInt(dayE[0]);
						var morning = [];
						var period = [];
						var nextHour = [];
						var timevar = {};
						var brs = [];
						this.ispreloader = true;
						this.PS.getSechudel(this.clinicKey, this.SelectedPract, this.SelectedAppointType, clickDate).subscribe(res => {
							if (res.length != 0) {
								for (var ti in res) {
									var NdayTime = res[ti].split('-');
									var NsTime = this.convertTo24Hour(NdayTime[0]);
									var NeTime = this.convertTo24Hour(NdayTime[1]);
									var NdayS = NsTime.split(':');
									var NdayE = NeTime.split(':');
									var nfdayS = new Date(cdate.getFullYear(), cdate.getMonth(), cdate.getDate());
									nfdayS.setHours(Number(NdayS[0]));
									nfdayS.setMinutes(parseInt(NdayS[1]));
									nfdayS.setSeconds(0);
									var nfdayE = new Date(cdate.getFullYear(), cdate.getMonth(), cdate.getDate());
									nfdayE.setHours(parseInt(NdayE[0]));
									nfdayE.setMinutes(parseInt(NdayE[1]));
									nfdayE.setSeconds(0);

									morning.push({ st: NdayTime[0], stime: nfdayS, etime: nfdayE, fulltime: res[ti] });
								}
							};
							this.Timeframe = morning;

							/*	setTimeout(() => {
									tabloader.style.height = tabloader.children[this.steps - 1].offsetHeight + "px";
									$('[data-toggle="tooltip"]').tooltip();
								}, 100);*/

							this.ispreloader = false;
						});
						this.next(stepper, tabloader, stepImage);

					}
				}
			};

			//  }


		}
	}
	imageloader(event, stepper, tabloader) {
		const cimg = event.target;

		stepper.classList.remove("is-in");
		setTimeout(() => {
			stepper.classList.add("is-loaded");
		}, 300)

		if (this.steps) {
			if (this.steps == 1) {
				this.progressbarView.nativeElement.style.width = "12.5%";
			}
			if (!this.isMobile) {
				/*	if (tabloader.children[this.steps - 1]) {
	
						tabloader.style.height = tabloader.children[this.steps - 1].offsetHeight + "px";
					}*/
			}
		}

	}

	prev(stepper, tabloader, stepImage) {
		stepper.classList.remove("is-loaded");
		if (this.steps > 1) {
			tabloader.classList.add("sliding");

			this.steps--;
			if (this.steps < 4) {
				this.SelectedDate = null;
			}
			this.changeImage(stepper, tabloader, stepImage);
			$('[data-toggle="tooltip"]').tooltip('hide');
			if (!this.isMobile) {
				setTimeout(() => {
					/*	if (tabloader.children[this.steps - 1]) {
							tabloader.style.height = tabloader.children[this.steps - 1].offsetHeight + "px";
						}*/
					tabloader.classList.remove("sliding");

				}, 300);
			}

		}


	}
	next(stepper, tabloader, stepImage) {
		stepper.classList.remove("is-loaded");

		if (this.steps < 8) {
			tabloader.classList.add("sliding");
			this.steps++;
			$('[data-toggle="tooltip"]').tooltip('hide');
			this.changeImage(stepper, tabloader, stepImage);
			if (!this.isMobile) {
				setTimeout(() => {
					/*
										if (tabloader.children[this.steps - 1]) {
												tabloader.style.height = (tabloader.children[this.steps - 1].scrollHeight + 40) + "px";
										}*/
					tabloader.classList.remove("sliding");
				}, 300);
			}

		}

		var currentPer = Math.round((this.steps / 8) * 100);
		this.progressWidth = currentPer;
		this.progressbarView.nativeElement.style.width = currentPer + "%";


	}


	changeImage(stepper, tabloader, stepImage) {
		stepper.classList.add("is-in");
		if (!this.isMobile) {
			if (tabloader.children[this.steps - 1]) {
				/*	tabloader.style.height = tabloader.children[this.steps - 1].offsetHeight + "px";*/
			}
		}


		if (this.imageUrl[this.steps - 1]) {
			setTimeout(() => {

				this.StepTitle = this.imageUrl[this.steps - 1].title;
				this.StepDesc = this.imageUrl[this.steps - 1].desc;
				stepImage.src = "assets/png/" + this.imageUrl[this.steps - 1].url;
			}, 200);



		}
		var currentPer = Math.round((this.steps / 8) * 100);
		this.progressWidth = currentPer;
		this.progressbarView.nativeElement.style.width = currentPer + "%";
	}



}
