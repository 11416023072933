import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

import { Initlist } from './_resolver/initlist.resolve';
import { OrderstatusR } from './_resolver/orderstatus.resolve';

import { ClinicdataGuard } from './_services/clinicdata.guard';
import { OrderstatusComponent } from './pages/orderstatus/orderstatus.component'
const routes: Routes = [
  {
    path: '', component: HomeComponent, canActivate: [ClinicdataGuard], resolve: {
      list: Initlist
    }
  }, {
    path: 'order/:type', component: OrderstatusComponent, canActivate: [ClinicdataGuard], resolve: { list: Initlist, status: OrderstatusR }
  },

  { path: '**', redirectTo: '' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [Initlist]
})
export class AppRoutingModule { }
