import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CalenderComponent } from './calender/calender.component';
import { UserFormComponent } from './user-form/user-form.component';
import { Token } from './_helper/token.interceptor';
import { InarrayPipe } from './_helper/inarray.pipe';

import { StripeModule } from "stripe-angular";
import { OrderstatusComponent } from './pages/orderstatus/orderstatus.component'



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CalenderComponent,
    UserFormComponent,
    InarrayPipe,
    OrderstatusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    BrowserAnimationsModule, DeviceDetectorModule.forRoot()
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: Token, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
