<div class="row justify-content-center min-vh-100">
  <div class="col-lg-8 col-md-10 min-vh-100">
    <div class="row no-gutters stepper justify-content-center" #stepper>
      <div class="col-md-4 border-right">
        <div class="step-main px-3 pt-3">
          <div class="mt-md-4">
            <div class="text-center mt-3 mb-4">
              <img
                [src]="GetBackLogo"
                class="img-fluid"
                style="max-height: 25px"
              />
            </div>
            <div class="text-center mb-2 mb-md-3">
              <img [src]="ClinicImageLogo" class="img-fluid" />
            </div>
            <h5 class="text-center text-secondary pb-2 fs-18 d-none">
              {{ ClinicName | titlecase }}
            </h5>
          </div>

          <div
            class="
              step-desc
              d-none
              flex-column
              align-items-center
              justify-content-center
              pb-4
            "
            [ngClass]="{ 'd-lg-none': steps == 5, 'd-lg-flex': steps < 6 }"
          >
            <div class="desc-group">
              <img
                src="assets/png/002-solution.png"
                width="80px"
                height="80px"
                class="d-none"
                (load)="imageloader($event, stepper, tabloader)"
                #stepImage
              />
            </div>
            <div class="desc-group">
              <h3 class="desc-title fs-18">{{ StepTitle }}</h3>
            </div>
            <div class="desc-group">
              <p class="fs-13 text-center">{{ StepDesc }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card border-0" [ngClass]="{ 'min-vh-100': isMobile }">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
              bg-transparent
            "
            style="min-height: 45px"
          >
            <h3
              class="card-title fs-16 mb-0 desc-title flex-fill"
              [ngClass]="{ 'text-success': steps == 8 }"
            >
              {{ StepTitle }}
              <span *ngIf="SelectedDate && steps == 6">
                For
                <span class="text-warning border-bottom border-warning">{{
                  SelectedDate | date: "mediumDate"
                }}</span>
              </span>
            </h3>
            <div *ngIf="steps > 1 && steps < 8" class="pl-3">
              <a
                [routerLink]=""
                class="text-secondary d-flex align-items-center"
                (click)="prev(stepper, tabloader, stepImage)"
              >
                <i class="las la-arrow-left fs-22 mr-2"></i>
                <span style="text-decoration: underline">Back</span>
              </a>
            </div>
          </div>

          <div class="card-body px-0 px-md-3 py-0 position-relative">
            <div
              class="progress position-absolute rounded-0 top-0 start-0 w-100"
              style="height: 8px; left: 0px"
            >
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                #progressbar
                [attr.aria-valuenow]="progressWidth"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div
              class="
                position-absolute
                w-100
                h-100
                d-flex
                justify-content-center
                align-items-center
              "
              style="z-index: 100; background-color: rgba(255, 255, 255, 0.65)"
              *ngIf="ispreloader"
            >
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="step-body pt-3" #tabloader>
              <div
                class="py-4 step-content"
                *ngIf="steps == 1"
                [@fadeInUpAnimation]="steps == 1 ? 'in' : 'out'"
              >
                <div class="list-group card-list servicelist">
                  <a
                    class="
                      list-group-item
                      card-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    href="javascript:void(0);"
                    (click)="
                      ServiceSelected(stepper, tabloader, stepImage, Service)
                    "
                    *ngFor="let Service of serviceList"
                    [ngClass]="{
                      'border-warning': SelectedService == Service['Id']
                    }"
                    ><span class="ser-title">{{ Service["Name"] }}</span></a
                  >
                </div>
              </div>
              <div
                class="py-4 step-content"
                *ngIf="steps == 2"
                [@fadeInUpAnimation]="steps == 2 ? 'in' : 'out'"
              >
                <div class="list-group card-list servicelist">
                  <a
                    class="
                      list-group-item
                      card-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    href="javascript:void(0);"
                    (click)="
                      PatientSelected(stepper, tabloader, stepImage, patientT)
                    "
                    *ngFor="let patientT of PatientType"
                    [ngClass]="{
                      'border-warning': PatientTypeId == patientT['Id']
                    }"
                    ><span class="ser-title">{{ patientT["Name"] }}</span></a
                  >
                </div>
              </div>
              <div
                class="py-4 step-content"
                *ngIf="steps == 3"
                [@fadeInUpAnimation]="steps == 3 ? 'in' : 'out'"
              >
                <div class="list-group card-list servicelist">
                  <a
                    class="
                      list-group-item
                      card-item
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    href="javascript:void(0);"
                    (click)="
                      AppTypeSelected(stepper, tabloader, stepImage, AppType)
                    "
                    *ngFor="let AppType of AppTypeList"
                    [ngClass]="{
                      'border-warning':
                        SelectedAppointType == AppType['PublicKey']
                    }"
                    ><span class="ser-title">{{ AppType["Name"] }}</span
                    ><span class="badge badge-success"
                      >{{ AppType["Duration"] }} mins</span
                    ></a
                  >
                </div>
              </div>
              <div
                class="py-4 step-content"
                *ngIf="steps == 4"
                [@fadeInUpAnimation]="steps == 4 ? 'in' : 'out'"
              >
                <div class="px-3">
                  <div
                    class="row card-list text-center"
                    *ngFor="let prow of prList"
                  >
                    <div class="col-md-4 mb-3" *ngFor="let pdata of prow">
                      <a
                        class="card p-2 card-item"
                        href="javascript:void(0);"
                        (click)="
                          practitionerSelected(
                            stepper,
                            tabloader,
                            stepImage,
                            pdata
                          )
                        "
                        [ngClass]="{
                          'border-warning': SelectedPract == pdata.PublicKey
                        }"
                        >{{ pdata.first_name }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="py-4 step-content"
                *ngIf="steps == 5"
                [@fadeInUpAnimation]="steps == 5 ? 'in' : 'out'"
              >
                <div class="row">
                  <div class="calender col-lg-8">
                    <app-calender
                      (DateSelected)="
                        DateSlected($event, stepper, tabloader, stepImage)
                      "
                      (MonthChanged)="calmonthChanged($event, stepper, tabloader, stepImage)"
                      [AvalDates]="Avaldate"
                    ></app-calender>
                  </div>
                </div>
                <div class="tooltip" role="tooltip">
                  <div class="arrow"></div>
                  <div class="tooltip-inner"></div>
                </div>
              </div>
              <div
                class="py-4 step-content h-100"
                *ngIf="steps == 6"
                [@fadeInUpAnimation]="steps == 6 ? 'in' : 'out'"
              >
                <div
                  *ngIf="SelectedDate && !ispreloader && Timeframe.length != 0"
                  class=""
                >
                  <div class="d-flex flex-wrap">
                    <div
                      *ngFor="
                        let time of Timeframe;
                        let inx = index;
                        let last = last
                      "
                      class="flex-wrap mb-2 mr-2"
                    >
                      <a
                        class="btn btn-sm"
                        href="javascript:void(0);"
                        [ngClass]="{
                          'is-break': time['isbreak'],
                          'btn-outline-secondary': time['isbreak'],
                          'btn-outline-success': !time['isbreak'],
                          active: SelectedStime == time['stime']
                        }"
                        (click)="
                          Timeselected(
                            time,
                            stepper,
                            tabloader,
                            stepImage,
                            'hr'
                          )
                        "
                        data-toggle="tooltip"
                        data-placement="top"
                        attr.title="{{ time['fulltime'] }}"
                      >
                        <span
                          ><span class="text-nowrap fs-14"
                            >{{ time["st"] }}
                          </span></span
                        >
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="SelectedDate && !ispreloader && Timeframe.length == 0"
                  class=""
                >
                  No Slots Available
                </div>
              </div>
              <div
                class="py-4 step-content h-100"
                *ngIf="steps == 7"
                [@fadeInUpAnimation]="steps == 7 ? 'in' : 'out'"
              >
                <app-user-form></app-user-form>
                <div class="d-none d-md-block">
                  <div class="row fs-14 mb-3 border-bottom">
                    <div class="col-4 mb-2 d-flex flex-column border-right">
                      <label class="text-muted fs-13 mb-1">Service Type</label>
                      {{ SelectedServiceName }}
                    </div>
                    <div class="col mb-2 d-flex flex-column border-right">
                      <label class="text-muted fs-13 mb-1">Patient Type</label>
                      {{ PatientypeName }}
                    </div>
                  </div>
                  <div class="row fs-14 mb-3 border-bottom">
                    <div class="col mb-2 d-flex flex-column">
                      <label class="text-muted fs-13 mb-1"
                        >Appointment Type</label
                      >
                      {{ SelectedAppointName }}
                    </div>
                    <div class="col mb-2 d-flex flex-column border-right">
                      <label class="text-muted fs-13 mb-1">Practitioner</label>
                      {{ SelectedPractName }}
                    </div>
                  </div>

                  <div class="row fs-14 mb-3 border-bottom">
                    <div class="col mb-2 d-flex flex-column">
                      <label class="text-muted fs-13 mb-1"
                        >Appointment Date</label
                      >

                      {{ SelectedDate | date: "mediumDate" }}
                    </div>
                    <div class="col mb-2 d-flex flex-column border-right">
                      <label class="text-muted fs-13 mb-1"
                        >Appointment Start Time</label
                      >
                      {{ SelectedTime["stime"] | date: "shortTime" }}
                    </div>
                    <div class="col mb-2 d-flex flex-column">
                      <label class="text-muted fs-13 mb-1"
                        >Appointment End Time</label
                      >
                      {{ SelectedTime["etime"] | date: "shortTime" }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pb-4 pt-2 step-content h-100"
                *ngIf="steps == 8"
                [@fadeInUpAnimation]="steps == 8 ? 'in' : 'out'"
              >
                <div class="mb-4 alert alert-primary-alt ls-12 fs-13 d-none">
                  One of our representative will get back to you shortly. Here
                  are the booking details.
                </div>

                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col-4 mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Service Type</label>
                    {{ SelectedServiceName }}
                  </div>
                  <div class="col-4 mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Patient Type</label>
                    {{ PatientypeName }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment Type</label
                    >
                    {{ SelectedAppointName }}
                  </div>
                </div>

                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Practitioner</label>
                    {{ SelectedPractName }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment Date</label
                    >

                    {{ SelectedDate | date: "mediumDate" }}
                  </div>
                </div>

                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment Start Time</label
                    >
                    {{ SelectedTime["stime"] | date: "shortTime" }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment End Time</label
                    >
                    {{ SelectedTime["etime"] | date: "shortTime" }}
                  </div>
                </div>
                <h5 class="pb-1 text-info border-bottom fs-14 mb-3">
                  Contact Info
                </h5>
                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">First Name</label>
                    {{ UserFormData["FirstName"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1">Last Name</label>
                    {{ UserFormData["LastName"] }}
                  </div>
                </div>
                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Mobile phone</label>
                    {{ UserFormData["PhoneNumber"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1">Email address</label>
                    {{ UserFormData["EmailId"] }}
                  </div>
                </div>
                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Comments</label>
                    {{ UserFormData["Comment"] }}
                  </div>
                </div>
              </div>
              <div
                class="py-4 step-content h-100"
                *ngIf="steps == 9"
                [@fadeInUpAnimation]="steps == 9 ? 'in' : 'out'"
              >
                <h4 class="text-success text-center">Thank You</h4>
                <h6 class="text-center">Booking was successful</h6>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent">
            <div class="row">
              <div class="col" *ngIf="steps > 1 && steps < 8">
                <button
                  class="
                    btn btn-link btn-sm
                    text-secondary text-decoration-none
                  "
                  (click)="prev(stepper, tabloader, stepImage)"
                >
                  <i class="las la-arrow-left"></i> Back
                </button>
              </div>
              <div class="col text-right d-none">
                <button
                  class="btn btn-warning btn-sm d-none"
                  *ngIf="steps < 6"
                  (click)="next(stepper, tabloader, stepImage)"
                >
                  Next <i class="las la-arrow-right"></i>
                </button>
              </div>
              <div class="col text-right" *ngIf="steps == 7">
                <button
                  class="btn btn-warning btn-sm text-nowrap"
                  (click)="formsubmit(stepper, tabloader, stepImage)"
                >
                  Confirm Booking
                </button>
              </div>

              <!--<div class="col text-right text-nowrap" *ngIf="steps==7"><button class="btn btn-warning btn-sm "   (click)="confirmBooking(stepper,tabloader,stepImage)">Confirm Booking  <i class="las la-arrow-right"></i></button></div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
