import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders,HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class Token implements HttpInterceptor{
	constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var usertoken=localStorage.getItem("auth");
const modified=request.clone({
	params:new HttpParams().set("token",usertoken),
});
	 
    return next.handle(modified);
  }
}