import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { EnVar } from '../config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ClinicService {
    constructor(http) {
        this.http = http;
        this.apiurl = EnVar.apiurl;
    }
    getservices() {
        var url = this.apiurl + "appointment/services/";
        var urlP = this.apiurl + "appointment/practitioners/";
        const response1 = this.http.get(url);
        const response2 = this.http.get(urlP);
        return forkJoin([response1, response2]);
    }
    updateingleApp(pk, orderId) {
        var url = this.apiurl + "appointment/appstatus/" + pk;
        var formData = new FormData();
        formData.append('orderid', orderId);
        formData.append('pkey', pk);
        return this.http.post(url, formData);
    }
    getsingleApp(pk) {
        var url = this.apiurl + "appointment/singleapp/" + pk;
        return this.http.get(url);
    }
}
ClinicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClinicService_Factory() { return new ClinicService(i0.ɵɵinject(i1.HttpClient)); }, token: ClinicService, providedIn: "root" });
