import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-xml2json";
import * as i3 from "@angular/platform-browser";
export class ClinicdataGuard {
    constructor(http, ngxXml2jsonService, titleService) {
        this.http = http;
        this.ngxXml2jsonService = ngxXml2jsonService;
        this.titleService = titleService;
    }
    canActivate(next, state) {
        var clinicData = localStorage.getItem("clinicdata");
        let self = this;
        /*if (clinicData == null) {
        } else {
          var jsonOld = JSON.parse(clinicData);
          this.titleService.setTitle(jsonOld["title"]);
    
          return true;
        }*/
        return this.http.get('./assets/clinic.xml', {
            headers: new HttpHeaders()
                .set('Content-Type', 'text/xml')
                .append('Access-Control-Allow-Methods', 'GET')
                .append('Access-Control-Allow-Origin', '*')
                .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
            responseType: 'text'
        }).pipe(map(data => {
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, 'text/xml');
            const result = this.ngxXml2jsonService.xmlToJson(xml);
            if (result && result['clinicdata']) {
                var clinicdataNew = result['clinicdata'];
                result['clinicdata']["stripekey"] = btoa(result['clinicdata']["stripekey"]);
                localStorage.setItem("clinicdata", JSON.stringify(result['clinicdata']));
            }
            return true;
        }));
    }
    xmlToJson(xml) {
        var obj = {};
        if (xml.nodeType == 1) {
            if (xml.attributes.length > 0) {
                obj["@attributes"] = {};
                for (var j = 0; j < xml.attributes.length; j++) {
                    var attribute = xml.attributes.item(j);
                    obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                }
            }
        }
        else if (xml.nodeType == 3) {
            obj = xml.nodeValue;
        }
        if (xml.childNodes) {
            for (var i = 0; i < xml.childNodes.length; i++) {
                var item = xml.childNodes.item(i);
                var nodeName = item.nodeName;
                if (typeof (obj[nodeName]) == "undefined") {
                    obj[nodeName] = this.xmlToJson(item);
                }
                else {
                    if (typeof (obj[nodeName].push) == "undefined") {
                        var old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item));
                }
            }
        }
        return obj;
    }
}
ClinicdataGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClinicdataGuard_Factory() { return new ClinicdataGuard(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NgxXml2jsonService), i0.ɵɵinject(i3.Title)); }, token: ClinicdataGuard, providedIn: "root" });
