import { Injectable } from '@angular/core';
import {
	Router, Resolve,
	RouterStateSnapshot,
	ActivatedRouteSnapshot
} from '@angular/router';
import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from 'rxjs';
import { mergeMap, take, catchError, map } from 'rxjs/operators';
import xml2js from 'xml2js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnVar } from '../config';
import { ClinicService } from '../_services/clinic.service';

@Injectable({
	providedIn: 'root',
})
export class Initlist implements Resolve<any> {
	initclinik = EnVar.initclinic;
	initpk = EnVar.initpk;

	constructor(private router: Router, private CS: ClinicService, private http: HttpClient) {


	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		localStorage.removeItem('auth');
		localStorage.removeItem('clinic');
		let self = this;
		var localCLinic = localStorage.getItem('clinicdata');

		var clinicdataS = JSON.parse(localStorage.getItem('clinicdata'));

		//var usertoken = btoa(clinicdataS['pk'] + ":" + clinicdataS['name'] + ":" + clinicdataS['OnlineBookingType'] + ":" + clinicdataS['IsOnlinePayment']);
		var NewUserData = JSON.stringify({ "pk": clinicdataS['pk'], "name": clinicdataS['name'], "OnlineBookingType": clinicdataS['OnlineBookingType'], "IsOnlinePayment": clinicdataS['IsOnlinePayment'] });
		/*	if (clinicdataS) {
			} else {
				return null;
			}*/
		var newUsertoken = btoa(NewUserData);
		localStorage.setItem("auth", newUsertoken);



		return this.CS.getservices();



	}
}
