import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from 'rxjs';
import { mergeMap, take, catchError, map } from 'rxjs/operators';
import xml2js from 'xml2js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnVar } from '../config';
import { ClinicService } from '../_services/clinic.service';
@Injectable({
    providedIn: 'root',
})

export class OrderstatusR implements Resolve<any> {
    initclinik = EnVar.initclinic;
    initpk = EnVar.initpk;
    constructor(private router: Router, private http: HttpClient, private cs: ClinicService) {


    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        var repdata = {}
        if (route["params"]["type"]) {
            if (route["params"]["type"] == "success") {
                repdata["status"] = "success";
                if (route["queryParams"]["orderid"] && route["queryParams"]["apid"]) {
                    repdata["appdata"] = this.cs.updateingleApp(route["queryParams"]["apid"], route["queryParams"]["orderid"]);
                }
            } else {
                repdata["status"] = "failed";
                repdata["appdata"] = this.cs.getsingleApp(route["queryParams"]["apid"])
            }
        }

        return repdata;

    }
}
