import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-calender',
	templateUrl: './calender.component.html',
	styleUrls: ['./calender.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class CalenderComponent implements OnInit {
	today: Date = new Date();
	months: object = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	Weeks: object = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
	NumWeeks: object;
	currentMonth: number;
	presentMonth: number;
	currentYear: number;
	CurrentDate: number;
	CalenderTitle: string;
	Days: number;
	DaysRag: object;
	firstDay: number;
	cellCount: number = 0;
	CalenderList: object[][] = [];
	SelectedDate: Date;
	SelectedTime: string;
	@Output() DateSelected = new EventEmitter<any>(true);
	@Output() MonthChanged = new EventEmitter<any>(true);
	@Output() TimeSelected = new EventEmitter<any>(true);
	@Input() AvalDates: object[] = [];
	CurrentAval: object[] = [];
	MonthChang: boolean = false;
	constructor() { }

	ngOnInit() {

		this.MonthChang = false;
		this.today.setHours(0, 0, 0, 0);
		this.SelectedDate = null;
		this.NumWeeks = [...Array(6).keys()];
		this.presentMonth = this.today.getMonth();
		this.currentMonth = this.today.getMonth();
		this.currentYear = this.today.getFullYear();
		this.CurrentDate = this.today.getDate();

		this.CalenderTitle = this.months[this.currentMonth] + ' ' + this.currentYear;

		this.Days = this.daysInMonth(this.currentMonth, this.currentYear);
		this.DaysRag = [...Array(this.Days).keys()];
		this.firstDay = this.GetFirstDay(this.currentMonth, this.currentYear);
		if (this.AvalDates.length != 0) {
			this.CurrentAval = this.AvalDates;
		}
		this.showCalendar();



	}
	ngOnChanges(changes: SimpleChanges) {
		if (this.MonthChang) {
			if (changes.AvalDates) {
				this.CurrentAval = changes.AvalDates.currentValue;

			} else {

			}
			this.showCalendar();
		}
	}
	splitArray(array, chunk) {
		var i, j, temparray = [];
		for (i = 0, j = array.length; i < j; i += chunk) {
			temparray.push(array.slice(i, i + chunk));

		}
		return temparray;
	}
	next(event) {
		event.preventDefault();
		this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
		this.currentMonth = (this.currentMonth + 1) % 12;

		this.CalenderTitle = this.months[this.currentMonth] + ' ' + this.currentYear;

		this.Days = this.daysInMonth(this.currentMonth, this.currentYear);
		this.DaysRag = [...Array(this.Days).keys()];
		this.firstDay = this.GetFirstDay(this.currentMonth, this.currentYear);
		this.CalenderList = [];


		this.MonthChanged.emit(this.currentMonth + 1);
		this.MonthChang = true;
	}


	previous(event) {
		event.preventDefault();
		this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
		this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
		this.CalenderTitle = this.months[this.currentMonth] + ' ' + this.currentYear;

		this.Days = this.daysInMonth(this.currentMonth, this.currentYear);
		this.DaysRag = [...Array(this.Days).keys()];
		this.firstDay = this.GetFirstDay(this.currentMonth, this.currentYear);
		this.CalenderList = [];
		/*this.showCalendar();*/
		this.MonthChanged.emit(this.currentMonth + 1);
		this.MonthChang = true;
	}

	showCalendar() {
		let date = 1;
		const zeroPad = (num, places) => String(num).padStart(places, '0');
		var dasy = this.today.getDay() || 7;

		for (let i = 0; i < 6; i++) {
			this.CalenderList[i] = [];
			var weeoklist = [];

			for (let j = 0; j < 7; j++) {
				if (i === 0 && j < this.firstDay - 1) {
					weeoklist.push(0);
				} else if (date > this.Days) {
				} else {
					var d_current = zeroPad(this.currentYear, 2) + '-' + zeroPad((this.currentMonth + 1), 2) + '-' + zeroPad(date, 2);
					var d_d = new Date(d_current);
					var isavalDate = this.CurrentAval.filter((item) => {

						return item["DayValue"] == date;
					});


					weeoklist.push({ day: date, date: d_d, isaval: Number(isavalDate[0]["DayAvailable"]) });

					date++;
				}
			}

			this.CalenderList[i] = weeoklist;

		}

		;

	}

	daysInMonth(iMonth, iYear) {
		return 32 - new Date(iYear, iMonth, 32).getDate();
	}
	GetFirstDay(month, year) {
		return (new Date(year, month)).getDay();
	}
	displayPTime(event, Cdate, calBody) {


	}
	displayTime(event, Cdate, calBody) {

		this.SelectedDate = Cdate['date'];

		this.DateSelected.emit(this.SelectedDate);
		event.target.classList.add("active");
	}


}
